import React from "react"


const RangeInput = ({ name, range, value, setValue, convert = x => x, unconvert = x => x, suffix = ''}) => {

return <div className="range-input">
    <input type="number" id={`${name}-min`} name={`${name}-min`}
      min={ convert(range.min) } 
      max={ Math.min(convert(range.max), convert(value.max)) } 
      value={ convert(value.min) } 
      onChange={ (e) => { setValue({ min: unconvert(e.target.value), max: value.max }) } }
    />
    <span>-</span>
    <input type="number" id={`${name}-max`} name={`${name}-max`}
      min={ Math.max(convert(range.min), convert(value.min)) } 
      max={ convert(range.max) } 
      value={ convert(value.max) } 
      onChange={ (e) => { setValue({ min: value.min, max: unconvert(e.target.value) }) } }
    />
    { suffix && <span>{ suffix }</span> }
  </div>
}

export default RangeInput
