import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { scrollToTarget, randomIntFromInterval, rangesOvelap } from "../util"
import { objectsEqual, getAllTerms, getSelectOptions, getAgeRange, getTeachingTimeLSRange, getTeachingTimeLURange } from "../util/data"
import { useSessionStorage } from "../util/useSessionStorage"

import RangeInput from "../parts/rangeInput"
import Select from "react-select"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"
import HeadingUnderline from "../parts/headingUnderline"
import LearningScenarioCard from "../parts/learningScenarioCard"
import LearningUnitCard from "../parts/learningUnitCard"

import What from "../images/inline/what.svg"
import Who from "../images/inline/who.svg"
import Where from "../images/inline/where.svg"
import With from "../images/inline/with.svg"


const Navigator = ({ location, pageContext }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [advancedSearch, setAdvancedSearch] = useSessionStorage("advancedSearch", false)
  const [searchMode, setSearchMode] = useSessionStorage("searchMode", "LS")

  const [resultsLU, setResultsLU] = useState([])
  const [resultsLS, setResultsLS] = useState([])

  const learningScenarios = pageContext.learningScenarios.allNodeLearningScenario.nodes
  const learningUnits = pageContext.learningUnits.allNodeLearningUnit.nodes

  // keyword
  const allMainTerms = useMemo(() => getAllTerms(learningUnits), [learningUnits])
  const [keyword, setKeyword] = useSessionStorage("keyword", "")
  const [keywordsList, setKeywordsList] = useState([])

  // selects
  const defaultSelectProps = { isMulti: true, blurInputOnSelect: true, isSearchable: false, className: "select-wrapper", classNamePrefix: "select" }

  const whatOptions = getSelectOptions(pageContext.terms.allTaxonomyTermSteamSubjects.nodes)
  const whoOptions = getSelectOptions(pageContext.terms.allTaxonomyTermRoles.nodes)
  const whereOptions = getSelectOptions(pageContext.terms.allTaxonomyTermLocations.nodes)
  const withOptions = getSelectOptions(pageContext.terms.allTaxonomyTermEngagement.nodes)
  const formatOptions = getSelectOptions(pageContext.terms.allTaxonomyTermLuFormat.nodes)
  const phaseOptions = getSelectOptions(pageContext.terms.allTaxonomyTermPhase.nodes)
  const skillsOptions = [
    {
      label: "STEM practices",
      options: getSelectOptions(pageContext.terms.allTaxonomyTermSkills.nodes)
    },
    {
      label: "Soft skills",
      options: getSelectOptions(pageContext.terms.allTaxonomyTermSoftSkills.nodes)
    },
    {
      label: "Management skills",
      options: getSelectOptions(pageContext.terms.allTaxonomyTermManagementSkills.nodes)
    }
  ]

  const [whatSelected, setWhatSelected] = useSessionStorage("whatSelected", [])
  const [whoSelected, setWhoSelected] = useSessionStorage("whoSelected", [])
  const [whereSelected, setWhereSelected] = useSessionStorage("whereSelected", [])
  const [withSelected, setWithSelected] = useSessionStorage("withSelected", [])
  const [formatSelected, setFormatSelected] = useSessionStorage("formatSelected", [])
  const [phaseSelected, setPhaseSelected] = useSessionStorage("phaseSelected", [])
  const [skillsSelected, setSkillsSelected] = useSessionStorage("skillsSelected", [])


  // ranges
  const ageLURange = useMemo(() => getAgeRange(learningUnits), [learningUnits])
  const ageLSRange = useMemo(() => getAgeRange(learningScenarios), [learningScenarios])
  const [ageLU, setAgeLU] = useSessionStorage("ageLU", {...ageLURange})
  const [ageLS, setAgeLS] = useSessionStorage("ageLS", {...ageLSRange})

  const teachingTimeLURange = useMemo(() => getTeachingTimeLURange(learningUnits), [learningUnits])
  const teachingTimeLSRange = useMemo(() => getTeachingTimeLSRange(learningScenarios), [learningScenarios])
  const [teachingTimeLU, setTeachingTimeLU] = useSessionStorage("teachingTimeLU", {...teachingTimeLURange})
  const [teachingTimeLS, setTeachingTimeLS] = useSessionStorage("teachingTimeLS", {...teachingTimeLSRange})


  // useEffect to set the search mode if searchMode param is found and valid
  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if(["LS","LU"].indexOf(params.get("searchMode")) !== -1) {
      setSearchMode(params.get("searchMode"))
    }
  }, [location.search, setSearchMode])

  // useEffect to update the 'autocompletion' keywords list of words that still match the keyword input field
  useEffect(() => {
    let keywordsMatched = []

    if(keyword) {
      const k = keyword.toLowerCase()
      keywordsMatched = allMainTerms.filter(term => {
        const t = term.toLowerCase()
        return (t.includes(k) && (t !== k))
      })
    }

    setKeywordsList(keywordsMatched)
  }, [ keyword, allMainTerms ])


  // useEffect to update the search results
  useEffect(() => {
    setLoading(true)

    setTimeout(() => {
      // start with all, following filter can filter out results
      let filtered = false
      let filteredLU = learningUnits
      let filteredLS = learningScenarios
      filteredLU.forEach(lu => {
        lu._search = null
      })
      filteredLS.forEach(ls => {
        ls._search = null
        ls._cnt = null
      })

      // check which topics ids, intersect with topics for each node
      if(whatSelected.length > 0) {
        filtered = true
        filteredLU = filteredLU.filter(node => {
          let tids = (node.relationships?.steam_subjects || []).map(t => t.drupal_id)
          let intersection = whatSelected.filter(term => tids.includes(term.value))
          return (intersection.length > 0)
        })
      }

      // check which role ids, intersect with roles for each node
      if(whoSelected.length > 0) {
        filtered = true
        filteredLU = filteredLU.filter(node => {
          let tids = (node.relationships?.partners || []).map(t => t.drupal_id)
          let intersection = whoSelected.filter(term => tids.includes(term.value))
          return (intersection.length > 0)
        })
      }

      // check which location ids, intersect with locations for each node
      if(whereSelected.length > 0) {
        filtered = true
        filteredLU = filteredLU.filter(node => {
          let tids = (node.relationships?.location || []).map(t => t.drupal_id)
          let intersection = whereSelected.filter(term => tids.includes(term.value))
          return (intersection.length > 0)
        })
      }

      // check which engagement ids, intersect with engagements for each node
      if(withSelected.length > 0) {
        filtered = true
        filteredLU = filteredLU.filter(node => {
          let tids = (node.relationships?.engagement || []).map(t => t.drupal_id)
          let intersection = withSelected.filter(term => tids.includes(term.value))
          return (intersection.length > 0)
        })
      }

      // filter the nodes by keyword (all main terms for now), maybe just text search code (a few lines down) instead of this
      if(keyword) {
        filtered = true
        filteredLU = filteredLU.filter(node => {
          const matchedTerms = node.main_terms.filter(term => term.toLowerCase().includes(keyword.toLowerCase()))
          return matchedTerms.length > 0
        })
      }

      // search LU of LS
      if(searchMode === "LU") {
        if(!objectsEqual(ageLU, ageLURange)) filtered = true
        // filter by age does not make sense when searching for LS since has aggregated age
        filteredLU = filteredLU.filter(lu => {
          let luMin = parseInt(lu.minimum_age) || 0
          let luMax = parseInt(lu.maximum_age) || 99
          let rangeMin = parseInt(ageLU.min) || 0
          let rangeMax = parseInt(ageLU.max) || 99
          return rangesOvelap(luMin, luMax, rangeMin, rangeMax )
        })

        // filter by teaching time does not make sense when searching for LS, since LS has aggregated teaching time itself
        filteredLU = filteredLU.filter(lu => {
          if(!objectsEqual(teachingTimeLU, teachingTimeLURange)) filtered = true
          // if no time specified make sure it gets not filtered out
          let time = (lu.teaching_time_minutes || 0) * (lu.duration_repeat || 0)
          return ((time || 9999) >= teachingTimeLU.min && (time || 0) <= teachingTimeLU.max)
        })

        // filter by format
        if(formatSelected.length > 0) {
          filtered = true
          filteredLU = filteredLU.filter(node => {
            let tids = (node.relationships?.format || []).map(t => t.drupal_id)
            let intersection = formatSelected.filter(term => tids.includes(term.value))
            return (intersection.length > 0)
          })
        }

        // filter by phase
        if(phaseSelected.length > 0) {
          filtered = true
          filteredLU = filteredLU.filter(node => {
            let intersection = phaseSelected.filter(term => term.value === node.relationships?.phase?.drupal_id)
            return (intersection.length > 0)
          })
        }

        // filter by competencies & skills
        if(skillsSelected.length > 0) {
          filtered = true
          filteredLU = filteredLU.filter(node => {
            let tids = [
              ...(node.relationships?.stem_skills || []).map(t => t.drupal_id),
              ...(node.relationships?.soft_skills || []).map(t => t.drupal_id),
              ...(node.relationships?.management_skills || []).map(t => t.drupal_id)
            ]
            let intersection = skillsSelected.filter(term => tids.includes(term.value))
            return (intersection.length > 0)
          })
        }

      } else {

        // filter by aggregated age
        filteredLS = filteredLS.filter(ls => {
          if(!objectsEqual(ageLS, ageLSRange)) filtered = true

          let lsMin = parseInt(ls.minimum_age) || 0
          let lsMax = parseInt(ls.maximum_age) || 99
          let rangeMin = parseInt(ageLS.min) || 0
          let rangeMax = parseInt(ageLS.max) || 99
          return rangesOvelap(lsMin, lsMax, rangeMin, rangeMax )
        })

        // filter by teaching time does not make sense when searching for LS, since LS has aggregated teaching time itself
        filteredLS = filteredLS.filter(ls => {
          if(!objectsEqual(teachingTimeLS, teachingTimeLSRange)) filtered = true
          // if no time specified make sure it gets not filtered out
          return ((ls.teaching_time_minutes || 9999) >= teachingTimeLS.min && (ls.teaching_time_minutes || 0) <= teachingTimeLS.max)
        })

        // match Learning Scenarios based on LU that made it though the LU filters
        let ids1 = filteredLU.map(lu => lu.id)
        filteredLS = filteredLS.filter(ls => {
          let ids2 = ls.relationships.learning_units.map(lu => lu.relationships.learning_unit_reference?.id)
          let intersection = ids1.filter(id => ids2.includes(id))
          ls._cnt = intersection.length
          return (intersection.length > 0)
        }).sort((a,b) => (a._cnt < b._cnt))
      }

      setFiltered(filtered)
      setResultsLU(filteredLU)
      setResultsLS(filteredLS)
      setLoading(false)

    }, randomIntFromInterval(50, 200))

  }, [
    learningScenarios, learningUnits,
    ageLSRange, ageLURange,
    teachingTimeLSRange, teachingTimeLURange,
    advancedSearch, searchMode,
    whatSelected, whoSelected, whereSelected, withSelected,
    keyword,
    ageLS, teachingTimeLS,
    formatSelected, phaseSelected, skillsSelected, ageLU, teachingTimeLU
  ])


  const handleSearchModeClick = (newSearchMode) => {
    setSearchMode(newSearchMode)
    scrollToTarget("wwww", 100)
  }


  const handleAdvancedSearchClick = (newAdvancedSearch) => {
    if(newAdvancedSearch === false) {
      setKeyword("")
      setAgeLU({...ageLURange})
      setAgeLS({...ageLSRange})
      setTeachingTimeLU({...teachingTimeLURange})
      setTeachingTimeLS({...teachingTimeLSRange})
    }

    setAdvancedSearch(newAdvancedSearch)
  }


  return (
    <Layout currentNavItem="open-schooling-materials">
      <Seo title={ t("SEARCH-TITLE") } />

      <Row>
        <div className="pane">
          <div className="pad-m">
            <HeadingUnderline title={ t("SEARCH-TITLE") } classes={["center"]} />
            <p className="introduction">{ t("SEARCH-SUBTITLE") }</p>

            <div className="big-toggle">
              <button className={searchMode === "LS" ? "active" : ""} onClick={ () => { handleSearchModeClick("LS") } }>
                <p>{ t("SEARCH-LS_SEARCH_DESCRIPTION") }</p>
                <span className="icon-button">{ t("SEARCH-LS_SEARCH_BUTTON") }</span>
              </button>

              <button className={searchMode === "LU" ? "active" : ""} onClick={ () => { handleSearchModeClick("LU") } }>
                <p>{ t("SEARCH-LU_SEARCH_DESCRIPTION") }</p>
                <h3 className="icon-button">{ t("SEARCH-LU_SEARCH_BUTTON") }</h3>
              </button>
            </div>
          </div>

          <div className="pad-m flow">
            <div className="wwww" id="wwww">
              <div className="what">
                <What className="wwww-illustration" />
                <h3 className="center">{ t("SEARCH-WHAT") }</h3>
                <Select options={whatOptions} value={whatSelected} onChange={ value => { setWhatSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHAT_PLACEHOLDER") } />
              </div>
              <div className="who">
                <Who className="wwww-illustration" />
                <h3 className="center">{ t("SEARCH-WHO") }</h3>
                <Select options={whoOptions} value={whoSelected} onChange={ value => { setWhoSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHO_PLACEHOLDER") } />
              </div>
              <div className="where">
                <Where className="wwww-illustration" />
                <h3 className="center">{ t("SEARCH-WHERE") }</h3>
                <Select options={whereOptions} value={whereSelected} onChange={ value => { setWhereSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHERE_PLACEHOLDER") } />
              </div>
              <div className="with">
                <With className="wwww-illustration" />
                <h3 className="center">{ t("SEARCH-WITH") }</h3>
                <Select options={withOptions} value={withSelected} onChange={ value => { setWithSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WITH_PLACEHOLDER") } />
              </div>
            </div>

            <div className="flow-2x center">
              <button type="button" className="icon-button" onClick={ () => { handleAdvancedSearchClick(!advancedSearch) } }>
                { advancedSearch ? t("SEARCH-SIMPLE_SEARCH") + " ↑" : t("SEARCH-ADVANCED_SEARCH") + " ↓" }
              </button>
            </div>

            <div className={ `flow open-closed ${advancedSearch ? "open" : "closed"}` }>

              <div className="autocomplete-field-wrapper center">
                <label htmlFor="keyword">{ t("SEARCH-KEYWORD_FILTER") }</label>
                <div className="autocomplete-field">
                  <input name="keyword" id="keyword" type="text" value={ keyword } onChange={ (e) => { setKeyword(e.target.value) } } onKeyDown={ (e) => { if(e.key === "Escape") { e.target.blur() } } } />
                  <ul>
                    { keywordsList.map(kw => <li key={ kw }>
                      <button onClick={ (e) => { setKeyword(e.target.innerText)} }>{ kw }</button>
                    </li>) }
                  </ul>
                </div>
              </div>

              { searchMode === "LS" && <div className="grid-columns">
                <div>
                  <label>{ t("SEARCH-AGE") }</label>
                  <RangeInput name="ageLS" range={ ageLSRange } value={ ageLS } setValue={ setAgeLS } suffix={ t("YRS") } />
                </div>
                <div>
                  <label>{ t("SEARCH-TEACHING_TIME") }</label>
                  <RangeInput name="teachingTimeLS" range={ teachingTimeLSRange } value={ teachingTimeLS } setValue={ setTeachingTimeLS } convert={ x => x/60 } unconvert={ x => x*60 } suffix={ t("HOURS") } />
                </div>
              </div> }

              { searchMode === "LU" && <div className="grid-columns flow-2x">
                <Select options={ formatOptions } value={ formatSelected } placeholder={ t("SEARCH-FORMAT") } {...defaultSelectProps} onChange={ value => { setFormatSelected(value) }} />
                <Select options={ phaseOptions } value={ phaseSelected } placeholder={ t("SEARCH-PHASE") } {...defaultSelectProps} onChange={ value => { setPhaseSelected(value) }} />
                <Select options={ skillsOptions } value={ skillsSelected } placeholder={ t("SEARCH-SKILLS") } {...defaultSelectProps} onChange={ value => { setSkillsSelected(value) }} />
              </div> }

              { searchMode === "LU" && <div className="grid-columns flow-2x">
                <div>
                  <label htmlFor="age-learning-unit">{ t("SEARCH-AGE") }</label>
                  <RangeInput name="ageLS" range={ ageLURange } value={ ageLU } setValue={ setAgeLU } suffix={ t("YRS") } />
                </div>
                <div>
                  <label>{ t("SEARCH-TEACHING_TIME") }</label>
                  <RangeInput name="teachingTimeLU" range={ teachingTimeLURange } value={ teachingTimeLU } setValue={ setTeachingTimeLU } convert={ x => x/60 } unconvert={ x => x*60 } suffix={ t("HOURS") } />
                </div>
              </div> }

            </div>

          </div>
        </div>
      </Row>

      <Row class="">
        { loading && <span className="loader"></span> }
        { !loading && <>
          <p className="introduction center">
            { filtered ? <>
              { searchMode === "LS" && t("SEARCH-LS_MATCH", {count: resultsLS.length}) }
              { searchMode === "LU" && t("SEARCH-LU_MATCH", {count: resultsLU.length}) }
            </> : <>
              { searchMode === "LS" && t("SEARCH-LS", {count: resultsLS.length}) }
              { searchMode === "LU" && t("SEARCH-LU", {count: resultsLU.length}) }
            </> }
          </p>

          { resultsLU.length === 0 && <p className="center">{ t("SEARCH-NO_RESULTS_SUGGESTION") }</p> }

          { searchMode === "LS" && <div className="search-results fr-columns one-third">
            { resultsLS.map(ls => <LearningScenarioCard key={ ls.id } ls={ ls } filtered={ filtered } /> ) }
          </div> }

          { searchMode === "LU" && <div className="search-results fr-columns half">
            { resultsLU.map(lu => <LearningUnitCard key={ lu.id } lu={ lu } /> ) }
          </div> }
        </> }
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Navigator
