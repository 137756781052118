
export const scrollToTarget = (targetId, pixelOffset) => {
  const element = document.getElementById(targetId)
  const elementPosition = element.getBoundingClientRect().top

  window.scrollTo({ top: elementPosition - pixelOffset + window.pageYOffset, behavior: "smooth" })
}


export const getLangUrlPrefix = (langcode) => {
  return langcode !== "en" ? langcode : ""
}


export const getLangPrefixFromUrl = (url, langs) => {
  let parts =  url.split("/")
  let langcode = null
  if(langs.includes(parts[1])) {
    langcode = parts.splice(1,1)[0]
  }
  return [langcode, parts.join("/")]
}


export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const rangesOvelap = function(r1min, r1max, r2min, r2max ) {
  if(r1min >= r2min && r1min <= r2max) return true
  else if(r1max >= r2min && r1max <= r2max) return true
  else if(r2min >= r1min && r2min <= r1max) return true
  else if(r2max >= r1min && r2max <= r1max) return true
  return false
}
